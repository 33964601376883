import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import StyledHero from "../components/StyledHero"
import styles from './project-template.module.css'
import AniLink from 'gatsby-plugin-transition-link'

const ProjectTemplate = ({ data }) => {
  const { title, description: { description }, image } = data.project

  return (
    <Layout>
        <StyledHero img={image.fluid}/>
        <section className={styles.template}>
          <div className={styles.center}>
            <h2>{title}</h2>
            <p className={styles.desc}>{description}</p>
            <AniLink fade to='/portfolio' className='btn-primary'>Back to Projects</AniLink>
          </div>
        </section>

    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        project:contentfulPortfolio(slug: {eq: $slug}) {
            title
            description {
                description
            }
            image {
                fluid {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }
`

export default ProjectTemplate
